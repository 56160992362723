import React, { useState, useRef } from 'react';
import './MapAndSignUp.css';
import emailjs from '@emailjs/browser';
import { signInButton } from "../../helpers/metaPixel";

export const MapAndSignUp = () => {
  const form = useRef();
  const [formIsValid, setFormIsValid] = useState({
    text: undefined,
    email: undefined,
    tel: undefined,
    course: undefined,
  });
  const [userValue, setUserValue] = useState({
    text: '',
    email: '',
    tel: '',
    course: '',
  })

  //EVENTS
  const handleChange = ({target: {name, value}}) => {
    setUserValue({...userValue, [name]: value})
    setFormIsValid({
      ...formIsValid,
      text: validateName(userValue.text),
      email: validateEmail(userValue.email),
      tel: validatePhone(userValue.tel),
      course: validateCourse(userValue.course),
    });
  }

  const submitHandler = (e) => {
    e.preventDefault();
    const { email, text, tel, course } = formIsValid;
    if(email === true && text === true && tel === true && course === true) {
      const template = userValue.course === 'BON PODARUNKOWY' ? 'template_pqia7lt' : 'template_924lej6'
      emailjs.sendForm('service_fuee7ri', template, form.current, 'm_8DjFKJhrz4Oull3')
      .then(() => {
        customAlert('Twój mail został wysłany!', 'Gratulacje!');
        setTimeout(() => {
          document.getElementById('dialogbox').style.display = "none";
          document.getElementById('dialogoverlay').style.display = "none";
        }, 4000)
      }).catch(() => {
        alert('Wystąpił błąd, spróbuj ponownie później.')
      });
      setUserValue({
        text: '',
        email: '',
        tel: '',
        course: '',
      })
    }

      signInButton();
  };

  //VALIDATORS
  const validateName = (value) => {
    return value.length >= 1;
  };

  const validateEmail = (value) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return validRegex.test(value);
  };

  const validateCourse = (value) => {
    return value !== undefined;
  }

  const validatePhone = (value) => {
    var regExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
    return regExp.test(value);
  };

  // RENDERS
  const renderTitle = () => {
    return <h1 className='title titleTeam'>ZAPISZ SIĘ NA KURS!</h1>;
  }

  const renderInputField = (name = '', title = '', value = '', change) => {
    return(
      <input className={'formSendInput'} type={name} id={name} name={name} placeholder={title} value={value} onChange={change}/>
    )
  }

  const renderLoginButton = () => {
    return <button className='sendFormButton' type="submit" disabled={!(formIsValid.text && formIsValid.email && formIsValid.tel && formIsValid.course)}>ZAPISZ SIĘ!</button>
  }

  const customAlert = (message, title) => {
      document.body.innerHTML = document.body.innerHTML + '<div id="dialogoverlay"></div><div id="dialogbox" class="slit-in-vertical"><div><div id="dialogboxhead"></div><div id="dialogboxbody"></div><div id="dialogboxfoot"></div></div></div>';
  
      let dialogoverlay = document.getElementById('dialogoverlay');
      let dialogbox = document.getElementById('dialogbox');
      
      let winH = window.innerHeight;
      dialogoverlay.style.height = winH+"px";
      
      dialogbox.style.top = "100px";
  
      dialogoverlay.style.display = "block";
      dialogbox.style.display = "block";
      
      document.getElementById('dialogboxhead').style.display = 'block';
  
      if(typeof title === 'undefined') {
        document.getElementById('dialogboxhead').style.display = 'none';
      } else {
        document.getElementById('dialogboxhead').innerHTML = '<i class="fa fa-exclamation-circle" aria-hidden="true"></i> '+ title;
      }
      document.getElementById('dialogboxbody').innerHTML = message;
  }

  const googleMapToCompany = () => {
    return (
      <>
        <div className={'mapContainer'}>
          <iframe title="map" width={"100%"} height={"100%"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Bolid.%20O%C5%9Brodek%20szkolenia%20kierowc%C3%B3w,%20Hallera%206,%20Ruda%20%C5%9Al%C4%85ska%20Ruda%20%C5%9Al%C4%85ska+()&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </>
    )
  }

  return (
    <>
      {renderTitle()}
      <div className={`containerForMap`}>
        {googleMapToCompany()}
        <div className={'contactForm'}>
          <form ref={form} onSubmit={submitHandler}>
              <div className={`control ${formIsValid.text === false ? 'invalid' : ''}`}>
                {renderInputField("text", 'imię i nazwisko', userValue.text, handleChange)}
              </div>
              <div className={`control ${formIsValid.email === false ? 'invalid' : '' }`}>
                {renderInputField("email", "adres email", userValue.email, handleChange)}
              </div>
              <div className={`control ${formIsValid.tel === false ? 'invalid' : '' }`}>
                {renderInputField("tel", "numer telefonu", userValue.tel, handleChange)}
              </div>
              <div className={`control ${formIsValid.course === false ? 'invalid' : '' }`}>
                <select name="course" className={'selectContainer'} value={userValue.course} onChange={handleChange}>
                  <option defaultValue>Wybierz z oferty:</option>
                  <option value="STANDARD">STANDARD</option>
                  <option value="PREMIUM">PREMIUM</option>
                  <option value="JAZDY DODATKOWE">JAZDY DODATKOWE</option>
                  <option value="BON PODARUNKOWY">BON PODARUNKOWY</option>
                </select>
              </div>
              {renderLoginButton()}
          </form>
        </div>
      </div>
    </>
  )
}
